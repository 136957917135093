<template>
	<!-- 登录后,如果账户的人民币资产>300 则进行弹窗提示 去绑定谷歌验证
	只在登录后 提示一次 刷新页面不在进行提示
	提示中的不在提示 选项 是保存在浏览器端 localStorage 中 如果选中 下次登录不在弹 -->
	<transition name="google-tip-fade">
		<div class="google-tip-container" v-if="isShow">
			<div class="tip-content" :class="{en:getLang=='en'}">
				<div class="close el-icon-close" @click="close"></div>
				<div class="title">{{$t('common.googleTip_title')}}</div>
				<div class="content">
					<p>{{$t('common.googleTip_text')}}</p>
				</div>
				<div class="google-tipfooter">
					<div>
						<el-checkbox class="google-checkbox" v-model="checked" @change="useChecked"></el-checkbox>
						<span class="tip">{{$t('common.googleTip_tip')}}</span>
					</div>
					<el-button type="primary google-button" @click="tolink">{{$t('common.googleTip_button')}}</el-button>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { exchangeCapital } from "~/server/asset/index.js";
export default {
	data() {
		return {
			checked: false,
			isShow: false,
			bindGoogle: false
		};
	},
	computed: {
		...mapState(['user','userReady']),
		...mapGetters(["isLogin", "getLang"])
	},
	watch:{
		userReady(val){
			if(val){
				this.init();
			}
		}
	},
	mounted() {
	},
	methods: {
		init(){
			if (!this.user.bindGoogle) {
				if (
					this.isLogin &&
					localStorage.getItem("weex:googletipcount") &&
					!localStorage.getItem("weex:googletip")
				) {
					// 然后判断用户资产
					exchangeCapital().then(res => {
						if (+res.code === 0) {
							var assetUSDT = res.data.totalUSDT;
							var assetcny = this.$store.getters.toCNYByUSDT(assetUSDT);
							if (assetcny >= 300) {
								this.isShow = true;
							}
						}
					});
				} else {
					localStorage.removeItem("weex:googletipcount");
				}
			}
		},
		useChecked(val) {
			if (val) {
				localStorage.setItem("weex:googletip", true);
			} else {
				localStorage.removeItem("weex:googletip");
			}
		},
		close() {
			this.isShow = false;
			// 关闭之后 不再展示 如果没有选择不在提示 会在 下次登录提示
			localStorage.removeItem("weex:googletipcount");
		},
		tolink() {
			localStorage.removeItem("weex:googletipcount");
			this.isShow = false;
			if (this.isLogin) {
				this.$router.push(this.$i18n.path("account/setting/bindgoogle"));
			}
		}
	}
};
</script>
<style lang="less">
.google-tip-container {
	.pf();
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3000;
	background: rgba(0, 0, 0, 0.5);
	.tip-content {
		.pf();
		width: 380px;
		box-sizing: border-box;
		padding: 38px;
		background: #fff;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		&.en {
			//width: 480px;
		}
		.close {
			.pa();
			right: 10px;
			top: 10px;
			cursor: pointer;
		}

		.title{
			text-align:center;
			font-size: 20px;
			color: @text-black;
			line-height: 1;
			margin-bottom: 20px;
		}
	}
	.content {
		p {
			font-size: 12px;
			color: @gray-P1;
			text-align: center;
			line-height: 1.5;
		}
	}
	.google-tipfooter {
		margin-top: 30px;
		.flex();
		.flex-main-justify();
		.flex-cross-center();

		.google-checkbox{
			margin-right: 0;
		}
	}
	.tip {
		font-size: 12px;
		color: #999999;
		margin-left: 5px;
	}
}

.google-tip-fade-enter,
.google-tip-fade-leave-active {
	opacity: 0;
	transform: translate(-50%, -100%);
}
</style>

