// 前端 统一 server 端服务封装
import { getInstance } from '../../utils/doRequest'
const commonInstance = getInstance('');

// 充提币记录
export const financeList = data => {
  return commonInstance.post('/v1/if/finance/financeList', data)
}

// 总资产
export const financeTotalCapital = data => {
  return commonInstance.post('/v1/if/finance/totalCapital', data)
}

// 币币资产
export const exchangeCapital = data => {
  return commonInstance.post('/v1/if/finance/exchangeCapital', data)
}


// 划转记录
export const transferList = data => {
  return commonInstance.post('/v1/assets/transferList', data)
}
